.PhoneInputCountry {
  border: none;
  background: #5852d633;
  border: 1px solid #5852d633;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  align-self: center;
  margin-right: 0;
}
.PhoneInput {
  width: 100%;
}
.PhoneInputCountrySelectArrow {
  display: none !important;
}
.PhoneInputCountryIcon--border {
  box-shadow: none;
}
.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  box-shadow: none;
}
