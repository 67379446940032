body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -moz-osx-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  background: #20232b;
  min-height: 100vh;
  overflow: hidden;
}

* {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
